/* eslint-disable require-jsdoc */
import LibPhoneNumber from 'google-libphonenumber';

const phoneType = LibPhoneNumber.PhoneNumberType;
const phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = LibPhoneNumber.PhoneNumberFormat;

export const getNationalPhoneNumber = (phoneNumberString) => {
  const number = phoneUtil.parse(phoneNumberString);

  return phoneUtil
    .format(number, PNF.NATIONAL)
    .replace(/ /g, '')
    .replace(/-/g, '');
};

export const getInternationalPhoneNumber = (phoneNumberString) => {
  return phoneUtil.format(phoneUtil.parse(phoneNumberString), PNF.E164);
};

export const getUsernameByInternationalPhoneNumber = (phoneNumberString) => {
  const number = phoneUtil.parse(phoneNumberString);
  const regionCode = phoneUtil.getRegionCodeForNumber(number);
  const nationalPhoneNumber = getNationalPhoneNumber(phoneNumberString);
  return `${regionCode}_${nationalPhoneNumber}`;
};

export const getInternationalPhoneNumberByUsername = (phoneNumberString) => {
  const phoneNumberAry = phoneNumberString.split('_');
  const region = phoneNumberAry[0];
  const phoneNumber = phoneNumberAry[1];
  const {values_} = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
  return `+${values_[1]}${values_[2]}`;
};

export const isValidPhoneNumber = (phoneNumberString, countryCode) => {
  if (phoneNumberString == null || phoneNumberString === '') {
    return false;
  }
  let phoneObject;
  try {
    phoneObject = phoneUtil.parse(phoneNumberString, countryCode);
  } catch (error) {
    return false;
  }
  phoneUtil.isValidNumberForRegion(phoneObject, countryCode);
  const type = phoneUtil.getNumberType(phoneObject);
  return type === phoneType.MOBILE;
};
